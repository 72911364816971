import React, { FormEvent } from 'react';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import { IUser } from '../models/user';
import { createUser } from '../service/user.service';
import { useDispatch } from 'react-redux';
import Keycloak from 'keycloak-js';
import { clearFormErrors } from '../actions/formError.actions';
import UserForm from '../components/forms/UserForm';
import config from '../config.json';

function Register({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    const dispatch = useDispatch();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<IUser>({
        id: keyCloak.idTokenParsed!.sub!,
        region: '',
        address_street: 'ZDF-Straße 1',
        address_house_number: '',
        address_room_number: '',
        address_zip: '55127',
        address_location: 'Mainz',
        phone_number: '',
        mobile_number: '',
        replacement_given_name: '',
        replacement_surname: '',
        replacement_region: '',
    });

    const handleSubmit = async (event: FormEvent): Promise<void> => {
        event.preventDefault();
        dispatch(clearFormErrors());

        setProcessing(true);
        try {
            await createUser(user, config.frontend);
        } catch (ex) {
            setProcessing(false);
            return;
        }
        setProcessing(false);

        window.location.reload();
    };

    return (
        <Container maxWidth="sm">
            <Grid container style={{ marginTop: '10%', marginBottom: 50 }}>
                <Grid item xs={12}>
                    <Paper variant="outlined" style={{ padding: 15 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom>Registrierung abschließen</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <UserForm user={user} onChange={setUser} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        type="submit"
                                        style={{ marginTop: 20 }}
                                        disabled={processing}
                                    >
                                        Speichern
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Register;
